import BumpsTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-texture/bumps/BumpsTemplate';

import { AMOUNT, BUMPS_FACE_LOCATIONS, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinTexture/Bumps/BumpsTemplate'
};

const createStory = props => () => ({
  components: { BumpsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <bumps-template v-bind="$options.storyProps" />
  </div>`
});

export const required = createStory({
  comedones: AMOUNT.NO,
  gender: GENDER.FEMALE
});

export const all = createStory({
  comedones: AMOUNT.MODERATELY,
  gender: GENDER.FEMALE,
  comedoLocations: [BUMPS_FACE_LOCATIONS.CHIN, BUMPS_FACE_LOCATIONS.CHEEKS],
  comedoLocationsVisible: true
});
